import React,{useState,useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Budget from './Budget';
import EInvoices from './EInvoices';
import LatestProducts from './LatestProducts';
import Sales from './Sales';
import InvoiceWaiting from './InvoiceWaiting';
import ProcessFailed from './ProcessFailed';
import InvoiceValidated from './InvoiceValidated';
import TrafficBySource from './TrafficBySource';
import InvoiceRejected from './InvoiceRejected';
import MonthlyUsageChart from './MonthlyUsageChart';
import {TilesSkelton} from 'src/common/placeholder';
import usePersistedState from 'src/utils/usePersistedState';

import { API } from "aws-amplify";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },paper: {
    'margin-top': '10px'

  }  
}));

const Dashboard = (props) => {
  const classes = useStyles();
  console.log('Dashboard props',props);
  const [isLoading, setIsLoading] = useState(false); 
  const [matrix, setMatrix] = usePersistedState('matrix',{
    "Downloaded":0,
    "Rejected":0,
    "Validation Failed":0,
    "Validated":0
  }); 
  useEffect(() => {
    const getdashboardMatrix = async () => {
      setIsLoading(true);
      try {
       let apiName = "OptiVIVeAPI";
       let path = "/b2c ";
       let myInit = {
         headers: { "Content-Type": "application/json" },
         body: { masterType: "User", UserID: props.userSession.UserName, tenantId:props.userSession.TenantId  },
         queryStringParameters: {
           module: "invoice",
           op: "getDashboardMatix"
         }
       };
        // var response=await API.post(apiName, path, myInit);
        // await setMatrix(response.data);
        await setMatrix({
          "Downloaded":0,
          "Rejected":0,
          "Validation Failed":0,
          "Validated":0
        });
        await setIsLoading(false);
     }
     catch (error) {
       console.log("set login time error",error); 
     } 
   } 
   getdashboardMatrix()
   },[]);

  return (
    <Page
      className={classes.root}
      title="Dashboard"
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        > 
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <InvoiceWaiting matrix={matrix} />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <ProcessFailed matrix={matrix} />
          </Grid> 
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <InvoiceRejected matrix={matrix} />
          </Grid> 
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <InvoiceValidated matrix={matrix} />
          </Grid> 
          <Grid
            item
            lg={9}
            md={12}
            xl={10}
            xs={12}
          >
            <EInvoices  {...props} />
          </Grid>
          <Grid
            item
            lg={3}
            md={6}
            xl={3}
            xs={12}
          >
          {/* <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <MonthlyUsageChart    matrix={matrix} />
          </Grid> */}
          {/* <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
            className={classes.paper}
          >
            <TrafficBySource  {...props} />
          </Grid> */}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
