/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:93be5e64-755f-4c3d-92de-9ffd7b84209b",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_X2JjcYBIC",
    "aws_user_pools_web_client_id": "77e1l2ove62ttuqk8bidcgn0vp",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "OptiVIVeAPI",
            "endpoint": "https://et5hx20xs0.execute-api.ap-south-1.amazonaws.com/qa",
            "region": "ap-south-1"
        }
    ],
    "aws_dynamodb_all_tables_region": "ap-south-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "b2ceinvoice-qa",
            "region": "ap-south-1"
        }
    ],
    "aws_user_files_s3_bucket": "optivive-store140811-qa",
    "aws_user_files_s3_bucket_region": "ap-south-1"
};


export default awsmobile;
