import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  FormControlLabel,
  Checkbox,
  Switch,
  withStyles,
  colors
} from '@material-ui/core';
import * as Yup from 'yup';
import Amplify, { API } from 'aws-amplify';
import awsconfig from 'src/aws-exports';
import { Formik } from 'formik';
Amplify.configure(awsconfig);

const useStyles = makeStyles(() => ({
  root: {}
}));


const PurpleSwitch = withStyles({
  switchBase: {
    color: colors.purple[300],
    '&$checked': {
      color: colors.purple[500],
    },
    '&$checked + $track': {
      backgroundColor: colors.purple[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

const Devices = (props, { className, ...rest }) => {
  const classes = useStyles();

  const [codeType, setCodeType] = useState('Add');
  const [filterSwitch, setFilterSwitch] = useState(false);
  console.log("props.userDetails", props.userDetails);
  //const [values, setValues] = useState({});
  const [BankValues, setBankValues] = useState({});
  const [BankDefvalues, setBankDefValues] = useState({
    gstin: "",
    AccNum: "",
    BankName: "",
    BankBranch: "",
    ifsc: "",
    vpa: "",
  });
  console.log("Bank values::", BankValues);

  // function handleTextChange (event) {
  //   setBankValues({
  //     ...BankValues,
  //     [event.target.name]: event.target.value
  //   });
  // };

  function handleCheckChange() {
    setFilterSwitch(!filterSwitch);
    let status = '';
    if (!filterSwitch) {
      status = 'Active';
    } else {
      status = '';
    }
    StatusChange(status);
  }
  async function StatusChange(status) {
    console.log('status', status);
    if (status === 'Active') {
      status = "Active"
    }
    else {
      status = "InActive";
    }
    // setIsLoading(true);      
    try {
      let apiName = "OptiVIVeAPI";
      let path = "/b2c ";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: {
          status: status,
          type: "bankdetails",
          UserID: props.userSession.UserName,
          tenantId: props.userSession.TenantId,
          gstin: props.DefaultBankData.gstin
        },
        queryStringParameters: {
          module: "user",
          op: "UpdatePaymentStatus"
        }
      };
      var response = await API.post(apiName, path, myInit);
      console.log("Updated Status ::", response);
      // await setdeviceData(response.data);        
      // await setIsLoading(false);
    }
    catch (error) {
      console.log(" error", error);
    }
  }


  async function handleDevicesSave() {
    // console.log("Updated Save values ::",values);


    // let apiName = "OptiVIVeAPI";
    // let path = "/b2c ";
    // var body = {};
    // body.type = "Device";
    // body.pk = '';
    // body.Features = "";
    // body.AccessCode = "";
    // body.UserID = props.userSession.UserName;
    // body.GSTIN = props.userDetails.GSTIN;
    // body.Name = props.userDetails.Name;
    // body.TenantId = props.userSession.TenantId;
    // body.Email = props.userSession.Email;
    // body.Status = "Pending";
    // let myInit = {
    //   headers: { "Content-Type": "application/json" },
    //   body: body,
    //   queryStringParameters: {
    //     module: "mobile",
    //     op: "deviceUpdate"
    //   }
    // };
    // console.log(myInit);
    // console.log('  props.userSession  ', props.userDetails);


    //  API.post(apiName, path, myInit).then(response => {
    //     console.log("UPDATE USER PROPERTY", response);


    //   }).catch(err => {
    //     console.log(err);

    //   }); 

  }

  useEffect(() => {

    console.log("New Values ::", props.DefaultBankData);
    setBankValues(props.DefaultBankData);
    Object.assign(BankValues, props.DefaultBankData);
    if (BankValues.status === "Active") {
      setFilterSwitch(true);
    }
    else {
      setFilterSwitch(false);
    }
    console.log("Set Values ::", BankValues.AccNum);
  }, [props]);


  const DisplayingErrorMessagesSchema = Yup.object().shape({
    AccNum: Yup.number()
      .required('Required'),
    BankName: Yup.string()
      .required('Required'),
    BankBranch: Yup.string()
      .required('Required'),
    ifsc: Yup.string()
      .required('Required'),
    vpa: Yup.string()
      .required('Required')


  });
  return (
    <Formik
      initialValues={{
        AccNum: BankValues.AccNum,
        BankName: BankValues.BankName,
        BankBranch: BankValues.BankBranch,
        ifsc: BankValues.ifsc,
        vpa: BankValues.vpa,
      }}
      validationSchema={
        DisplayingErrorMessagesSchema

      }
      onSubmit={async (values) => {
        console.log('ADD :: ', values);

      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          autoComplete="off"
          noValidate
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader
              classes={{ root: classes.carAction }}
              subheader=""
              title="Bank Settings"
              action={
                <>
                  <FormControlLabel
                    control={<PurpleSwitch checked={filterSwitch} onChange={handleCheckChange} name="checkedA" />}
                    label="Receive Online Payment"
                  />
                </>}
            />
            <Divider />

            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    error={Boolean(touched.AccNum && errors.AccNum)}
                    helperText={touched.AccNum && errors.AccNum}
                    label="Account Number"
                    name="AccNum"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    value={values.AccNum}
                    variant="outlined"
                  />

                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.BankName && errors.BankName)}
                    helperText={touched.BankName && errors.BankName}
                    fullWidth
                    label="Bank Name"
                    name="BankName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    value={values.BankName}
                    variant="outlined"

                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                   error={Boolean(touched.BankBranch && errors.BankBranch)}
                   helperText={touched.BankBranch && errors.BankBranch}
                    fullWidth
                    label="Bank Branch"
                    name="BankBranch"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    value={values.BankBranch}
                    variant="outlined"

                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                   error={Boolean(touched.ifsc && errors.ifsc)}
                   helperText={touched.ifsc && errors.ifsc}
                    fullWidth
                    label="IFSC"
                    name="ifsc"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.ifsc}
                    variant="outlined"

                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                   error={Boolean(touched.vpa && errors.vpa)}
                   helperText={touched.vpa && errors.vpa}
                    fullWidth
                    label="VPA"
                    name="vpa"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    value={values.vpa}
                    variant="outlined"

                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  {/* <TextField
                fullWidth
                label="Company Name"
                name="company"
                onChange={handleChange}
                required
                value={values.CompanyName?values.CompanyName:""}
                variant="outlined"
                defaultValue="Disabled" disabled
              />             */}

                </Grid>
              </Grid>
              <Divider />
              {/* {codeType == 'Add' ? <Box
                display="flex"
                justifyContent="flex-end"
                p={2}
              >
                <Button
                  variant="contained"
                  className="errorIcon"
                  color="primary"
                  onClick={(event, row) => {
                    handleDevicesSave()
                  }}
                >
                  Update
          </Button>
              </Box> : <></>} */}
            </CardContent>
            <Divider />
          </Card>
        </form>
      )}
    </Formik>
  );
};

Devices.propTypes = {
  className: PropTypes.string
};

export default Devices;
